import fireEmoji from 'assets/emoji/fire.webp';
import plusPlanEmoji from 'assets/emoji/grinning_face_1.webp';
import proPlanEmoji from 'assets/emoji/grinning_face_2.webp';
import growthPlanEmoji from 'assets/emoji/grinning_face_3.webp';

import {
	Description,
	FreePlanInfo,
	Name,
	PaidPlanInfo,
	PerExcess,
	PlanLimits,
	PopularContainer,
	Price
} from './style';

const plansEmojis = {
	Plus: plusPlanEmoji,
	Pro: proPlanEmoji,
	Growth: growthPlanEmoji
};

const formatNum = (strNum = 0) => parseFloat(strNum).toLocaleString('en');

export const PlanInfoCard = ({
	planData: { name, isPersonalised, perMonth, perMonthAnn, perExcess, included, upTo, isFree }
}) => {
	if (isFree) {
		return (
			<FreePlanInfo>
				<h2>{name}</h2>

				<span>Try BizPrint free!</span>

				<p>You get {upTo} prints free and then you need to upgrade</p>
			</FreePlanInfo>
		);
	}

	if (isPersonalised) {
		return (
			<PaidPlanInfo>
				<Name plan={name}>Personal</Name>

				{perMonth && perMonthAnn ? (
					<Price aria-label="Annual term. Monthly pricing available">${perMonthAnn / 100}</Price>
				) : perMonthAnn ? (
					<Price aria-label="Annual term">${perMonthAnn / 100}</Price>
				) : (
					<Price aria-label="Monthly term">${perMonth / 100}</Price>
				)}

				<FreePrints included={included} plan={name} />

				<PerExcess planName={name}>
					+${perExcess / 100}
					<br /> each additional print
				</PerExcess>

				<PlanLimits>
					{upTo ? `Plan limits ${formatNum(upTo)} prints per month` : `No printing limits`}
				</PlanLimits>
			</PaidPlanInfo>
		);
	}

	return (
		<PaidPlanInfo>
			<PopularLabel isShown={name === 'Pro'} />

			<Name plan={name}>{name}</Name>

			<Price aria-label="Annual term. Monthly pricing available">${perMonthAnn / 100}</Price>

			<FreePrints included={included} plan={name} />

			<PerExcess planName={name}>
				+${perExcess / 100}
				<br /> each additional print
			</PerExcess>

			<PlanLimits>
				{upTo ? `Plan limits ${formatNum(upTo)} prints per month` : `No printing limits`}
			</PlanLimits>
		</PaidPlanInfo>
	);
};

export const PopularLabel = ({ isShown }) => {
	if (isShown) {
		return (
			<PopularContainer>
				<img src={fireEmoji} width="15px" height="15px" alt="" /> POPULAR
			</PopularContainer>
		);
	}

	return null;
};

export const FreePrints = ({ included, plan }) => {
	const emojiUrl = plansEmojis[plan];

	return (
		<Description>
			{formatNum(included)} prints free{' '}
			{emojiUrl && <img src={emojiUrl} width="16px" height="16px" alt="" />}
		</Description>
	);
};
