import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useLocation } from 'react-router-dom';

import Header from 'components/Header';
import { PrimeSpinner } from 'components/Spinners';
import FaIcon from 'components/ui/Icons';
import PageHeader from 'components/ui/PageHeader';

import useUserTasks from 'hooks/swr/useUserTasks';

import { fetchApplicationsInfo } from 'features/applications/applications-info';
import { createApplication } from 'features/applications/applications-list';

import { ConfirmationFormControl } from './ConfirmationFormControl';
import { WebhookInfoModal } from './WebhookInfoModal';
import {
	Button,
	Container,
	Error,
	Form,
	HeaderIcon,
	Input,
	Label,
	Select,
	WebhookInfo
} from './style';

const Create = () => {
	const { mutate: updateUserTasksList } = useUserTasks();

	const [formData, setFormData] = useState({});
	const [idApplication, setIdApplication] = useState(null);
	const [errors, setErrors] = useState({});
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [creatingApplication, setCreatingApplication] = useState(false);

	const { count, appsIncluded, perExcessApp, isFreePlan, loading } = useSelector(
		({ applicationsInfo }) => applicationsInfo
	);

	const appLimitReached = count >= appsIncluded;

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchApplicationsInfo());
	}, []);

	useEffect(() => {
		if (formData.version === '0')
			setFormData((prevState) => ({ ...prevState, webhookUrl: undefined }));
	}, [formData.version]);

	const from = useLocation().state?.from || '/';

	const onFormChange = (e) => {
		setErrors((prevErrorData) => ({
			...prevErrorData,
			[e.target.name]: undefined
		}));

		setFormData((prevFormData) => ({
			...prevFormData,
			[e.target.name]: e.target.value
		}));

		if (
			e.target.name === 'platform' &&
			e.target.value !== 'wordpress' &&
			formData.version === '0'
		) {
			setFormData((prevFormData) => ({ ...prevFormData, version: undefined }));
		}
	};

	const onConfirmChange = (e) => {
		setIsConfirmed(e.target.checked);
	};

	const submitCreateApplication = async (e) => {
		e.preventDefault();

		try {
			setCreatingApplication(true);

			const idApplication = await dispatch(createApplication({ ...formData }));

			setCreatingApplication(false);
			setIdApplication(idApplication);
			updateUserTasksList();
		} catch (e) {
			setErrors(e.response.data.errors);
		}
	};

	if (loading) return <PrimeSpinner />;

	if (creatingApplication) return <PrimeSpinner message="Creating application" />;

	if (idApplication) return <Navigate to={`/applications/${idApplication}`} />;

	return (
		<Container>
			<PageHeader>
				<Link to={from} aria-label="Back to the list of applications">
					<HeaderIcon icon="backNavigation" type="regular" />
				</Link>

				<Header text="Create Application" icon="applications" />

				<HeaderIcon />
			</PageHeader>

			<Form onSubmit={submitCreateApplication}>
				{errors.name && <Error>{errors.name}</Error>}
				<Label>
					Name
					<Input
						type="text"
						name="name"
						required
						value={formData.name ?? ''}
						placeholder="Enter your application name"
						onChange={onFormChange}
					/>
				</Label>

				{errors.homeUrl && <Error>{errors.homeUrl}</Error>}
				<Label>
					URL
					<Input
						type="text"
						name="homeUrl"
						required
						value={formData.homeUrl ?? ''}
						placeholder="Enter your URL"
						onChange={onFormChange}
					/>
				</Label>

				{errors.platform && <Error>{errors.platform}</Error>}
				<Label>
					Platform
					<Select name="platform" required value={formData.platform} onChange={onFormChange}>
						<option hidden value="">
							Select Platform
						</option>
						<option value="wordpress">WordPress</option>
						<option value="custom">Custom</option>
					</Select>
				</Label>

				{formData.platform != null && (
					<>
						{errors.version && <Error>{errors.version}</Error>}
						<Label>
							API Version
							<Select
								name="version"
								required
								value={formData.version ?? ''}
								onChange={onFormChange}
							>
								<option hidden value="">
									Select API Version
								</option>

								<option value="1">REST API</option>
								{formData.platform === 'wordpress' && (
									<option value="0">Legacy API [Not recommended]</option>
								)}
							</Select>
						</Label>
					</>
				)}

				{formData.version != null && (
					<>
						{errors.webhookUrl && <Error>{errors.webhookUrl}</Error>}
						<Label>
							Webhook URL
							<WebhookInfoModal />
							{formData.version === '0' ? (
								<WebhookInfo>Required REST API</WebhookInfo>
							) : (
								<Input
									type="text"
									name="webhookUrl"
									value={formData.webhookUrl ?? ''}
									placeholder="Enter your URL"
									onChange={onFormChange}
								/>
							)}
						</Label>
					</>
				)}

				{appLimitReached ? (
					<ConfirmationFormControl
						isFreePlan={isFreePlan}
						isConfirmed={isConfirmed}
						perExcessApp={perExcessApp}
						handleChanges={onConfirmChange}
					/>
				) : null}

				<Button type="submit" value="Create" disabled={appLimitReached && !isConfirmed} />
			</Form>
		</Container>
	);
};

export default Create;
