import axios from 'axios';
import formatPlan from 'models/formatPlan';

const currentPlanIsLoading = () => {
	return {
		type: 'FETCH_CURRENT_PLAN_REQUEST'
	};
};
export const currentPlanRequested = (payload) => {
	return {
		type: 'FETCH_CURRENT_PLAN_SUCCESS',
		payload
	};
};
export const currentPlanListError = (error) => {
	return {
		type: 'FETCH_CURRENT_PLAN_ERROR',
		payload: error
	};
};

const currentPlan = () => {
	return async (dispatch) => {
		dispatch(currentPlanIsLoading());
		try {
			const {
				data: { plan, isHavePaymentMethod = null, isTrialSelected }
			} = await axios.get('/subscription-monitor');

			dispatch(currentPlanRequested({ plan: formatPlan(plan), isHavePaymentMethod }));
		} catch (e) {
			dispatch(currentPlanListError(e));
		}
	};
};

export { currentPlan };
