import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
	count: null,
	hasWebhookError: null,
	appsIncluded: null,
	perExcessApp: null,
	isFreePlan: null,
	loading: true,
	error: null
};

const applicationsInfoSlice = createSlice({
	name: 'applications-info',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.hasWebhookError = null;
			state.appsIncluded = null;
			state.perExcessApp = null;
			state.isFreePlan = null;
			state.count = null;
			state.error = null;
		},
		handleData: (state, action) => {
			state.loading = false;
			state.error = null;
			state.count = action.payload.count;
			state.hasWebhookError = action.payload.hasWebhookError;
			state.appsIncluded = action.payload.appsIncluded;
			state.perExcessApp = action.payload.perExcessApp;
			state.isFreePlan = action.payload.isFreePlan;
		},
		handleError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.hasWebhookError = null;
			state.appsIncluded = null;
			state.perExcessApp = null;
			state.isFreePlan = null;
			state.count = null;
		}
	}
});

export default applicationsInfoSlice.reducer;

export const fetchApplicationsInfo = () => {
	return async (dispatch) => {
		dispatch(applicationsInfoSlice.actions.loading());

		try {
			const {
				data: { count = 0, hasWebhookError, appsIncluded, perExcessApp, isFreePlan }
			} = await axios.get(`applications/info`);

			dispatch(
				applicationsInfoSlice.actions.handleData({
					count,
					hasWebhookError,
					appsIncluded,
					perExcessApp,
					isFreePlan
				})
			);
		} catch (e) {
			dispatch(applicationsInfoSlice.actions.handleError(e));
		}
	};
};
