import { useEffect } from 'react';

import styled from 'styled-components';

const PlanTermSwitcher = ({ perMonth, perMonthAnn, planTerm, setPlanTerm }) => {
	const handleChangeTerm = (e) => e.target.checked && setPlanTerm(e.target.value);

	useEffect(() => {
		if (perMonth == null && perMonthAnn != null) return setPlanTerm('annual');

		if (perMonth != null && perMonthAnn == null) return setPlanTerm('monthly');
	}, [perMonth, perMonthAnn]);

	if (!perMonth || !perMonthAnn) return null;

	return (
		<TermSelection>
			<ul role="radiogroup" aria-label="Choose a Plan Term">
				<li>
					<input
						id="monthly"
						type="radio"
						name="planTerm"
						value="monthly"
						checked={planTerm === 'monthly'}
						onChange={handleChangeTerm}
					/>

					<label htmlFor="monthly">Monthly</label>
				</li>

				<li>
					<input
						id="annual"
						type="radio"
						name="planTerm"
						value="annual"
						checked={planTerm === 'annual'}
						onChange={handleChangeTerm}
					/>

					<label htmlFor="annual">Annual</label>
				</li>
			</ul>

			{planTerm === 'annual' && <p>Save Up to 25%</p>}
		</TermSelection>
	);
};

export default PlanTermSwitcher;

const TermSelection = styled.section`
	margin: 1rem 0;

	ul {
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: 0;

		li {
			label {
				cursor: pointer;
				display: inline-block;
				min-width: 130px;
				padding: 5px 0;
				border: 1px solid #a2aec1;
				border-radius: 6px 0 0 6px;
				text-align: center;
				font-weight: 600;
				background-color: #ffffff;
				color: #a2aec1;

				&:hover {
					background-color: rgba(162, 174, 193, 0.25);
				}
			}

			input {
				appearance: none;
				width: 0;

				&:focus + label {
					outline: 1px solid #050505;
				}

				&:checked + label {
					padding: 7px 20px;
					color: #ffffff;
					background-color: #a2aec1;
					box-shadow: -1px 1px 2px #373a3f;
				}
			}

			&:nth-of-type(1) label {
				border-right: none;
			}

			&:nth-of-type(2) {
				label {
					border-left: none;
					border-radius: 0 6px 6px 0;
				}

				input:checked + label {
					box-shadow: 1px 1px 2px #373a3f;
				}
			}
		}
	}

	p {
		margin: 0.8rem 0 0;
		text-align: center;
		font-size: 1.3rem;
		font-weight: 600;
		color: #53c691;
	}
`;
