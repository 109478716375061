import styled, { css } from 'styled-components';

import { Button as CustomButton } from 'components/ui/Buttons';

export const getBackgroundColor = (planName) => {
	switch (planName) {
		case 'Trial':
			return '#8bc9dc';
		case 'Basics':
			return '#a0befa';
		case 'Plus':
			return '#b49efa';
		case 'Pro':
			return '#ffac33';
		case 'Growth':
			return '#ff5d5b';
		default:
			return '#ccc';
	}
};

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: ${({ isFreePlan }) => (isFreePlan ? '230px' : '254px')};

	@media only screen and (max-width: 1400px) {
		width: 260px;
	}
`;

export const PopularContainer = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: center;
	width: 80%;
	padding: 4px 0 2px;
	border-radius: 25px;
	font-size: 13px;
	letter-spacing: 0.8px;
	color: #626160;
	background-color: #ffefdb;
	font-weight: 800;

	img {
		width: 13px;
		height: 13px;
		margin-right: 3px;
	}
`;

export const PaidPlanInfo = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 320px;
	padding: 1rem 0 0.5rem;
	border: solid 2px #e2e8f0;
	border-bottom: none;
	border-radius: 10px 10px 0 0;
	font-family: 'Work Sans', 'sans-serif';
	background-color: #ffffff;

	${PopularContainer} {
		position: absolute;
		top: 10px;
	}
`;

export const FreePlanInfo = styled(PaidPlanInfo)`
	justify-content: center;
	text-align: center;

	h2 {
		width: 100%;
		margin: 0;
		color: #a3afc2;
		font-size: 36px;

		font-weight: 600;
	}

	span {
		display: block;
		margin: 10px 0;
		color: #88c8dc;
		font-size: 22px;
	}

	p {
		margin: 0;
		font-size: 0.8rem;
		color: #a8b4c5;
	}
`;

export const Name = styled.h2`
	width: 100%;
	margin: 0;
	padding-top: 20px;
	font-size: 36px;
	font-weight: 600;
	text-align: center;
	color: ${({ plan }) => getBackgroundColor(plan)};
`;

export const Price = styled.p`
	margin: 1.2rem auto 0.6rem;
	font-size: 46px;
	font-weight: 600;
	color: #555555;

	::after {
		content: '/mo';
		font-size: 24px;
		font-weight: 600;
		color: #bbbbbb;
	}
`;

export const PerExcess = styled.p`
	margin: auto 0 0.4rem;
	font-size: 0.9rem;
	line-height: 1.3rem;
	text-align: center;
`;

export const Description = styled.p`
	display: flex;
	align-items: center;
	margin: 0;
	font-size: 0.9rem;
	font-weight: 700;
	color: #555555;

	img {
		margin-left: 5px;
	}
`;

export const PlanLimits = styled.p`
	margin: 0;
	padding: 0;
	font-size: 13px;
	text-align: center;
	color: #a3b0c2;
`;

export const Button = styled(CustomButton)`
	--btnColor: ${({ planName }) => getBackgroundColor(planName)};

	width: 100%;
	height: 45px;

	background-color: var(--btnColor);
	color: #fff;

	border: none;
	border-radius: 0 0 10px 10px;

	font-size: ${(props) => props.fontSize || '1rem'};
	font-weight: bold;

	z-index: 2;

	&:hover {
		background-color: #fff;
		border: 2px solid var(--btnColor);
		color: var(--btnColor);
	}

	&:disabled {
		border: 2px solid #e2e8f0;
		border-top: none;
		font-size: 0.9rem;
		color: var(--btnColor);
		background-color: #fff;
		cursor: default;

		&:hover {
			background-color: #fff;
			border-color: #e2e8f0;
			border-top: none;
		}
	}

	${({ planName, current, outline, noHover }) => {
		if (current) {
			return css`
				background-color: #fff;
				color: var(--btnColor);
				border: 2px solid #e2e8f0;
				border-top: none;

				&:hover {
					border-radius: 0 0 10px 10px;
					background-color: var(--btnColor);
					color: #fff;
				}

				${() =>
					!['Basics', 'Trial', 'Personalised'].includes(planName) &&
					css`
						border-bottom: none;
						border-radius: 0;
					`}
			`;
		}

		if (outline) {
			return css`
				background-color: #fff;
				color: var(--btnColor);
				border: 2px solid var(--btnColor);

				&:hover {
					background-color: var(--btnColor);
					color: #fff;
				}
			`;
		}

		if (noHover) {
			return css`
				border: 2px solid #e2e8f0;
				border-top: none;
				font-size: 0.9rem;
				color: var(--btnColor);
				background-color: #fff;
				cursor: default;

				&:hover {
					background-color: #fff;
					border-color: #e2e8f0;
					border-top: none;
				}

				${() =>
					!['Basics', 'Trial', 'Personalised'] &&
					css`
						border-bottom: none;
						border-radius: 0;

						&:hover {
							border-bottom: none;
						}
					`}
			`;
		}
	}}
`;

export const Features = styled.div`
	margin-top: -0.5rem;
	padding: 1rem 0.5rem 0.5rem;
	border: solid 2px #e2e8f0;
	border-top: none;
	border-radius: 0 0 10px 10px;
	background-color: #ffffff;
	z-index: 1;

	h3 {
		margin: 0.2rem 0 0.4rem;
		font-size: 1rem;
		font-weight: 600;
		color: #a3b0c2;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		color: ${({ planName }) => getBackgroundColor(planName)};

		li {
			font-size: 0.9rem;
			font-weight: 700;
			margin-top: 0.5rem;
		}
	}
`;
